<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/150.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 STATIC DISSIPATIVE BOTTLES

 
 
 


                </h4>
                <h5 style="text-align: center">SINT-313</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/151.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 PE STICKY ROLLER
 




                </h4>
                <h5 style="text-align: center">SINT-260</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/151.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ROLLER HANDLE
 
 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-260-H</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/152.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                WRIST STRAP TESTER
 
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-500</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
        <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/153.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                WRIST BAND & FOOTWEAR TEST STATION

 

 


                </h4>
                <h5 style="text-align: center">SINT-502</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/154.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                PORTABLE IONIZER





                </h4>
                <h5 style="text-align: center">SINT-503</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/155.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  HUMIDITY INDICATOR CARDS

 
                  
 


                </h4>
                <h5 style="text-align: center">SINT-504</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/156.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 SAFETY GLASSES

 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-882</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
        <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/157.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  SAFETY GLASSES


 
 


                </h4>
                <h5 style="text-align: center">SINT-883</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/158.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  SAFETY GLASSES

 




                </h4>
                <h5 style="text-align: center">SINT-893</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/159.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 CONDUCTIVE RUBBER BAND
                  
 


                </h4>
                <h5 style="text-align: center">SINT-WL-13</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 50% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/160.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                CONDUCTIVE ROPES

 
 
                  
                 
 


                </h4>
                <h5 style="text-align: center">SINT-WL-20</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/161.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC ALVEOLATE FILM, BLACK
 
 
 


                </h4>
                <h5 style="text-align: center">SINT-WL-31</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/tester">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80% ;margin:auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/162.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  ANTI-STATIC ALVEOLATE FILM, TRANSPARENT

 




                </h4>
                <h5 style="text-align: center">SINT-WL-32</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      
      </div>
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>